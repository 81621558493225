define(['lodash', 'warmupUtils/pointers/pointerGeneratorsRegistry'], function (_, pointerGeneratorsRegistry) {
    'use strict';

    const type = 'partners';
    const isPotentialPartner = 'isPotentialPartner';


    pointerGeneratorsRegistry.registerPointerType(type, _.constant(null), _.constant(true));

    function getIsPotentialPartnerPointer(getItemAt, cache) {
        return cache.getPointer(isPotentialPartner, type);
    }
    const getterFunctions = Object.freeze({
        getIsPotentialPartnerPointer
    });

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(type, getterFunctions);
});
